
.blog-list-page {
    padding: 2rem;
    max-width: 80%;
    margin: 0 auto;
  }

  .blog-hero {
    text-align: center;        
    margin-top: 8rem;         
    margin-bottom: 6rem;       
  }
  
  .blog-hero-title {
    position: relative;
    display: inline-block;
    padding-bottom: 0.5rem;
    font-size: 2.5rem;     
    margin-bottom: 1rem;
    font-weight: 400;       
    color: #222;          
  }

  .blog-hero-title::after {
    content: "";
    display: block;
    width: 200px; 
    height: 2px;
    background-color: #ddd;
    margin: 0.5rem auto 0;
  }
  
  .blog-hero-subtitle {
    font-size: 1.125rem;    
    color: #666;
    margin: 0 auto;
    max-width: 600px;        
    line-height: 1.6;
  }
  
  .blog-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  
  @media (min-width: 700px) {
    .blog-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1200px) {
    .blog-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }


  .blog-detail-page {
    max-width: 900px;   
    margin: 0 auto;      
    padding: 2rem;
    color: #000;         
  }
  
  .blog-detail-metadata {
    text-align: left;    
    margin-top: 12rem;         
    margin-bottom: 6rem;
  }
  
  .blog-detail-category {
    display: block;
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  .blog-detail-title {
    font-size: 2rem;
    font-weight: 600;    
    margin: 0 0 0.5rem;
    line-height: 1.3;    
    color: #000;
  }
  
  .blog-detail-date {
    display: block;
    font-size: 0.85rem;
    color: #666; 
    margin-bottom: 2rem; 
  }

  .blog-detail-date::after {
    content: "";
    display: block;
    width: 50px;
    height: 2px;
    background: #ccc;
    margin: 0.7rem 0 0; 
  }
  
  .blog-detail-image-wrapper {
    text-align: center;
    margin-bottom: 5rem; 
  }

  .blog-detail-image {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
    border-radius: 6px;
  }

  .blog-detail-authors {
    font-size: 0.9rem;
    color: #777;
    margin-top: 3rem;
    text-align: left; 
  }
  
  
  .blog-detail-content {
    font-size: 1rem;
    line-height: 1.6;
    text-align: justify;  
    color: #333;
    margin: 0 auto;
    max-width: 800px;
  }

  .blog-content p {
    font-size: 1rem;
    line-height: 1.5; 
    margin-bottom: 1.2rem; 
    color: #333; 
  }

  .blog-content h2 {
    font-size: 2.2rem; 
    font-weight: 600; 
    margin-bottom: 2.5rem; 
    line-height: 1.3;
    color: #222; 
  }

  .blog-content h3 {
    font-size: 1.6rem;
    font-weight: 500; 
    margin-bottom: 2rem;
    line-height: 1.2;
    color: #444; 
  }

  .blog-content h4 {
    font-size: 1.4rem; 
    font-weight: 400; 
    margin-bottom: 1.5rem;
    line-height: 1.2;
    color: #555;
  }

  .blog-detail-recommended {
    margin-top: 4rem; 
  }
  
  .blog-detail-recommended h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  

  .recommended-blogs-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  

  @media (min-width: 900px) {
    .recommended-blogs-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .recommended-blog-card {
    background-color: #f9f9f9;
    border-radius: 6px;
    padding: 1rem;
    cursor: pointer;
    transition: box-shadow 0.2s ease;
    display: flex;
    flex-direction: column;
  }
  .recommended-blog-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .recommended-blog-image-wrapper {
    width: 100%;
    height: 120px;
    overflow: hidden;
    border-radius: 4px;
    margin-bottom: 0.5rem;
  }
  .recommended-blog-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .recommended-blog-category {
    display: block;
    font-size: 0.85rem;
    color: #666;
  }
  
  .recommended-blog-title {
    display: -webkit-box;         
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 3;    
    overflow: hidden;
    text-overflow: ellipsis;


    font-size: 1rem;
    font-weight: 600;
    margin: 0.5rem 0;
    color: #000;
  }
  
  .recommended-blog-date {
    font-size: 0.85rem;
    color: #666;
    margin-top: auto;
  }

  .blog-card {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background: #fff;
    color: #000;     
    transition: color 0.4s ease;
    width: 100%;
    border-radius: 3px;
  }
    
    .blog-card:hover {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    }
    
    .blog-card-image-container {
      width: 100%;
      height: 260px; 
      overflow: hidden;
    }
    
    .blog-card-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
    
    .blog-card-footer {
      position: relative;  
      background-color: #F6F6F6;
      padding: 1rem 1.5rem;
      transition: background-color 0.3s ease, color 0.3s ease;
      min-height: 180px;
      overflow: hidden;
      display: flex;
      flex-direction: column;  
    }
  
    .blog-card-category {
      display: block;         
      font-size: 0.85rem;    
      color: #666;         
      margin-bottom: 0.5rem;  
    }
    
    .blog-card-title {
    display: -webkit-box;         
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 3;    
    overflow: hidden;
    text-overflow: ellipsis;    
    
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0; 
    color: #000;
    text-align: left;
    line-height: 1.4;
  }
  
  
    .blog-card-date {
      display: block;
      font-size: 0.8rem;
      color: #666;
      margin-top: auto;
    }
    
  
    .blog-card:hover
    .blog-card-title,
    .blog-card:hover .blog-card-category,
    .blog-card:hover .blog-card-date {
    color: #fff;
    }
  
    .blog-card-footer::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: var(--tertiary-color, #D92525);
      transition: width 0.3s ease;
      z-index: 0;
    }
    
  
    .blog-card-footer > * {
      position: relative;
      z-index: 1;
    }
    
  
    .blog-card:hover .blog-card-footer::after {
      width: 100%;
    }
    
    .blog-card:hover .blog-card-title,
    .blog-card:hover .blog-card-category,
    .blog-card:hover .blog-card-date {
      color: #fff;
    }
  
  



